<template>
    <UsersTable />
</template>

<script>
import UsersTable from '../../components/users/UsersTable.vue';

export default {
    components: {
        UsersTable
    }
}
</script>