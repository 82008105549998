<template>
    <section>
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-6 wow fadeInRight">
                    <h2>We offer customers a wide range of <span class="id-color">commercial cars</span> and <span class="id-color">luxury cars</span> for any occasion.</h2>
                </div>
                <div class="col-lg-6 wow fadeInRight" data-wow-delay=".25s">
                    Welcome to RentRover, your trusted partner in luxury car rentals. With a passion for driving excellence and a commitment to delivering unparalleled service, we're here to elevate your travel experience.

                    At RentRover, we believe that every journey should be exceptional. Our fleet of meticulously maintained luxury vehicles is handpicked to offer you the finest selection. Whether you're embarking on a business trip or a leisurely escape, our diverse range of vehicles ensures you'll find the perfect match for your preferences and needs.
                </div>
            </div>
            <div class="spacer-double"></div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutUs'
}
</script>