<template>
    <CategoryForm />
</template>

<script>
import CategoryForm from '../../../components/category/CategoryForm.vue'
export default {
    components: {
        CategoryForm
    }
}
</script>