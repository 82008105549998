<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <h2>Explore the world with comfortable car</h2>
                    <div class="spacer-20"></div>
                </div>
                <div class="col-md-3">
                    <i class="fa fa-trophy de-icon mb20"></i>
                    <h4>First Class Services</h4>
                    <p>Where luxury meets exceptional care, creating unforgettable moments and exceeding your every expectation.</p>
                </div>
                <div class="col-md-3">
                    <i class="fa fa-road de-icon mb20"></i>
                    <h4>24/7 road assistance</h4>
                    <p>Reliable support when you need it most, keeping you on the move with confidence and peace of mind.</p>
                </div>
                <div class="col-md-3">
                    <i class="fa fa-map-pin de-icon mb20"></i>
                    <h4>Free Pick-Up & Drop-Off</h4>
                    <p>Enjoy free pickup and drop-off services, adding an extra layer of ease to your car rental experience.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Explore'
}
</script>