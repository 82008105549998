<template>
    <CarTable />
</template>

<script>
import CarTable from '../../components/car/CarTable.vue';

export default {
    components: {
        CarTable
    }    
}
</script>