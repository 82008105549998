<template>
    <CategoryTable />
</template>

<script>
import CategoryTable from '../../components/category/CategoryTable.vue';
export default {
    components: {
        CategoryTable
    }
}
</script>