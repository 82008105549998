<template>
    <BlogForm />
</template>

<script>
import BlogForm from '../../../components/blog/BlogForm.vue';

export default {
    components: {
        BlogForm
    }   
}
</script>