<template>
    <CarForm />
</template>

<script>
import CarForm from '../../../components/car/CarForm.vue';

export default {
    components: {
        CarForm
    }    
}
</script>