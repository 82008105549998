<template>
    <BlogList />
</template>

<script>
import BlogList from '../components/blog/BlogList.vue';
export default {
    components: {
        BlogList
    }
}
</script>