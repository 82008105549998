<template>
    <BrandTable />
</template>

<script>
import BrandTable from '../../components/brand/BrandTable.vue';
export default {
    components: {
        BrandTable
    }
}
</script>