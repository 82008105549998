<template>
    <BrandForm />
</template>

<script>
import BrandForm from '../../../components/brand/BrandForm.vue'
export default {
    components: {
        BrandForm
    }
}
</script>