<template>
    <RegisterForm />
</template>

<script>
import RegisterForm from '../components/auth/RegisterForm.vue';

export default {
    components: {
        RegisterForm
    }    
}
</script>