<template>
    <BlogTable />
</template>

<script>
import BlogTable from '../../components/blog/BlogTable.vue';
export default {
    components: {
        BlogTable
    }
}
</script>